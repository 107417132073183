.profile-picture {
    text-align: center;
    img {
        height: auto;
        width: 400px;
        margin: 10px auto;
        max-width: -webkit-fill-available;
        border-radius: 250px;
    }
}
