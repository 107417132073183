.timeline {
  padding-bottom: 90px;
}
.container {
  // background-color: #eaeaea;
  margin-top: -2px;
}
div {
  padding-bottom: 1px;
}
h1.font,
h4.font,
h2.title,
h3.vertical-timeline-element-title,
h4.vertical-timeline-element-subtitle {
  text-align: center;
}
p {
  text-align: justify;
  max-width: 500px;
  margin: auto;
}
.title {
  color: #037c1d;
  text-shadow: 0 0 1px #fff;
}
.vertical-timeline-element-date {
  color: rgb(0, 0, 0);
}
.profile-description {
  p {
    text-align: center;
  }
  div {
    max-width: 550px;
    margin: auto;
    visibility: visible;
    animation: custom-bounce2 0.6s;
  }
}
.blue-bg-link {
  color: #61d9fb;
}

@media (max-width: 1169px) {
  #work .vertical-timeline-element-date:nth-of-type(1) {
    color: rgb(255, 255, 255);
    opacity: 1;
  }
  .profile-description > div {
    max-width: 1250px;
    margin: auto;
    visibility: visible;
    animation: custom-bounce1 0.6s;
  }

  h3.vertical-timeline-element-title,
  h4.vertical-timeline-element-subtitle {
    text-align: unset;
  }
}
@keyframes custom-bounce2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes custom-bounce1 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
